<template>
  <div class="community">
    <div class="community-banner">
      <img src="@/assets/banner/2-1.png" alt="" />
      <div class="community-banner-content" style="padding-left: 35%;margin-top: 8%">
<!--        <h1 style="margin-top:20px">开源社区</h1>
        <p class="desc">开源社区与你同行，共赴企业赋能升级</p>-->
<!--        <el-input-->
<!--          class="community-banner-input"-->
<!--          size="large"-->
<!--          v-model="keyword"-->
<!--          placeholder="请输入您要查找的团队"-->
<!--          prefix-icon="el-icon-search"-->
<!--          @keydown.enter.native="onChange"-->
<!--        />-->
<!--        <el-button @click="onCreate" type="primary"> 创建新社区 </el-button>-->
        <!--        <el-button type="primary" plain>加入社区</el-button>-->
      </div>
    </div>
    <div class="community-filter">
      <div class="community-filter-position">
        <div class="name">地点</div>
        <v-distpicker
            hide-area
            @change-province="onChangeProvince1"
            @change-city="onChangeCity1"
            :placeholders="placeholders"
        />
        <el-button @click="onCreate" type="primary" style="margin-left: 10%"> 创建新社区 </el-button>
<!--        <div class="value">-->
<!--          <p :class="{ selected: city === '' }" @click="onSelect('')">全部</p>-->
<!--          <p-->
<!--            :class="{ selected: city === item }"-->
<!--            v-for="(item, i) in cityList"-->
<!--            :key="i"-->
<!--            @click="onSelect(item)"-->
<!--          >-->
<!--            {{ item }}-->
<!--          </p>-->
<!--        </div>-->

<!--        <div @click="isEx = !isEx" class="more">-->
<!--          {{ isEx ? "收起" : "更多" }}-->
<!--        </div>-->
      </div>
      <div class="community-filter-other">
        <div>其他</div>
        <div class="value">
          <span>排序</span>
          <el-select
            v-model="orderType"
            @change="onChange"
            placeholder="请选择"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="community-list">
      <div
        v-for="(item, i) in list"
        :key="i"
        class="community-list-cell"
        @click="toDetail(item.id)"
      >

        <template v-if="localStorageType==1">
          <img
              :src="
            item.communityIntroductionPicture ||
            require('@/assets/kyyl-nt.png')
          "
              alt=""
              style="max-width: 50%; max-height: 50%;"
          />
        </template>
        <template v-else-if="localStorageType==2">
          <img
              :src="
            item.communityIntroductionPicture ||
            require('@/assets/kyqy-nt.png')
          "
              alt=""
              style="max-width: 50%; max-height: 50%;"
          />
        </template>


        <div class="community-list-cell-right">
          <h1>{{ item.communityName }}</h1>
          <p class="icon">
            <span> <el-icon class="el-icon-location" />{{ item.city }} </span>
            <span>
              <el-icon class="el-icon-user" />{{ item.memberCount }} 家
            </span>
            <span>
              <el-icon class="el-icon-collection" />{{ item.caseCount }} 个
            </span>

            <span v-if="getUserInfo && getUserInfo.userName &&item.userId==getUserInfo.id" @click="openAdminEditCommunity(item.id)" >
              <el-icon class="el-icon-collection" />编辑
            </span>
          </p>
          <p><label>介绍：</label>{{item.communityIntroductionSimple && item.communityIntroductionSimple.length > 100 ? item.communityIntroductionSimple.substring(0, 100) + '...' : item.communityIntroductionSimple}} </p>

        </div>
      </div>
    </div>
    <div class="community-pagination">
      <el-pagination
        :current-page.sync="currentPage"
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="10"
        @current-change="initData"
      />
    </div>
    <el-dialog title="创建社区" width="40%" :visible.sync="dialogFormVisible">
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item label="社区名称" prop="communityName">
          <el-input
            v-model="form.communityName"
            autocomplete="off"
            placeholder="选择后社区名称自动生成"
            readonly disabled
          />
        </el-form-item>
        <el-form-item label="社区介绍" prop="communityIntroductionSimple">
          <el-input
            v-model="form.communityIntroductionSimple"
            autocomplete="off"
            type="textarea"
            rows="4"
            placeholder="请输入社区介绍"
          />
        </el-form-item>
        <el-form-item label="社区省份/地市" prop="city">
          <v-distpicker
            hide-area
            @change-province="onChangeProvince"
            @change-city="onChangeCity"
          />
        </el-form-item>
        <el-form-item label="公司名称" prop="companyName">
          <el-input
            v-model="form.companyName"
            autocomplete="off"
            placeholder="请输入公司名称"
          />
        </el-form-item>
        <el-form-item label="公司联系人名字" prop="companyContactName">
          <el-input
            v-model="form.companyContactName"
            autocomplete="off"
            placeholder="请输入公司联系人名字"
          />
        </el-form-item>
        <el-form-item label="公司联系人手机号" prop="contactPhone">
          <el-input
            v-model="form.contactPhone"
            autocomplete="off"
            placeholder="请输入公司联系人手机号"
          />
        </el-form-item>
        <el-form-item label="公司类型" prop="companyType">
          <!-- 下拉选择框-->
          <el-select v-model="form.companyType" placeholder="请选择">
            <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="社区申请理由" prop="reason">
          <el-input
            v-model="form.reason"
            autocomplete="off"
            type="textarea"
            rows="4"
            placeholder="请输入社区申请理由"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" :disabled="isSubmitting" @click="submitForm"> 确 定 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { createCommunity, getCommunityList,getMyApplyInfo,getCommunityProvinceCount } from "@/api/community";
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      placeholders: {
        province: '全部省份',
        city: '全部城市',
      },
      localStorageType: localStorage.getItem("type") || 2,
      keyword: "",
      province:"",
      isSubmitting: false,
      city: "",
      isEx: false,
      orderType: "",
      options2: [
        {
          value: "资源",
          label: "资源",
        },
        {
          value: "技术",
          label: "技术",
        },
        {
          value: "实施",
          label: "实施",
        },
        {
          value: "用户",
          label: "用户",
        },
      ],
      options: [
        {
          label: "创建日期",
          value: "create_time asc",
        },
        {
          label: "案例数",
          value: "case_count desc",
        },
        {
          label: "成员数量",
          value: "member_count desc",
        },
      ],
      list: [],
      currentPage: 1,
      total: 0,
      dialogFormVisible: false,
      form: {
        communityName: "",
        communityIntroductionSimple: "",
        companyName: "",
        companyContactName: "",
        contactPhone: "",
        companyType: "",
        reason: "",
        province: "",
        city: "",
      },
      rules: {
        communityName: [
          { required: true, message: "社区格式为省份+开源医疗+001", trigger: "blur" },
        ],
        communityIntroductionSimple: [
          { required: true, message: "请输入社区介绍", trigger: "blur" },
        ],
        companyName: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        companyContactName: [
          { required: true, message: "请输入公司联系人名字", trigger: "blur" },
        ],
        contactPhone: [
          {
            required: true,
            message: "请输入公司联系人手机号",
            trigger: "blur",
          },
        ],
        companyType: [
          { required: true, message: "请输入公司类型", trigger: "blur" },
        ],
        reason: [
          { required: true, message: "请输入社区申请理由", trigger: "blur" },
        ],
        province: [
          { required: true, message: "请输入社区省份", trigger: "blur" },
        ],
        city: [{ required: true, message: "请输入社区地市", trigger: "blur" }],
      },
    };
  },

  computed: {
    cityList() {
      const cityList = [
        "成都",
        "昆明",
        "西安",
        "郑州",
        "武汉",
        "重庆",
        "广州",
        "合肥",
      ];
      return this.isEx ? cityList : cityList.slice(0, 10);
    },
    ...mapGetters("user", ["getUserInfo"]),
  },

  methods: {
    toDetail(id) {
      this.$router.push({
        name: "community-detail",
        params: { id },
      });
    },

    async initData(index) {
      try {
        const { province,city, orderType, keyword } = this;
        const { data } = await getCommunityList(index, {
          province,
          city,
          orderType,
          keyword,
        });
        this.list = data.communityList;
        this.total = data.total;
        console.log(data);
      } catch (e) {
        console.log(e);
      }
    },

    async initData2() {
      try {
        if(!this.getUserInfo){
          return;
        }
        const {data} = await getMyApplyInfo();
        // this.list = data.communityList;
        // this.total = data.total;
        console.log("查询我的信息");
        console.log(data);
        if (data) {
          const myInfo = data;
          console.log(myInfo)
          this.form.companyName = myInfo.applyCompanyName;
          this.form.companyContactName = myInfo.applyUserName;
          this.form.city = myInfo.city;
          this.form.province = myInfo.province;
          this.form.contactPhone = myInfo.applyPhone;
          console.log("setting form");
          console.log(this.form);
        }
      } catch (e) {
        console.log(e);
      }
    },

    onSelect(item) {
      this.city = item;
      this.onChange();
    },

    onChange() {
      this.currentPage = 1;
      this.initData(1);
    },

    onCreate() {
      if (localStorage.getItem("token") || this.$store.state.user.token) {
        this.dialogFormVisible = true;
      } else {
        this.$Bus.$emit("showLogin", "show");
      }
    },

    submitForm() {

      if (this.isSubmitting) {

        return; // 如果正在提交，直接返回，不再处理重复提交

      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.isSubmitting = true; // 标记为正在提交状态
          createCommunity(this.form)
            .then(() => {
              this.dialogFormVisible = false;
              this.$message.success("创建成功，等待审核");
            })
            .catch((e) => {
              console.log(e);
            })
              .finally(() => {
                this.isSubmitting = false; // 恢复为非提交状态，不论成功或失败
              });
        } else {
          console.log("请填写必填内容");
          return false;
        }
      });
    },

    openAdminEditCommunity(communityId) {
      window.open(
        "http://opencom.com.cn:40008/#/community/detail/" + communityId
      );
    },
    onChangeProvince1(data) {
      console.log(data);
      this.province = data.value;
      if(this.province=="全部省份"){
        this.province="";
      }
      this.city = "";
      this.onChange();
      //查询身份现在有的开源社区个数

    },

    onChangeCity1(data) {
      this.city = data.value;
      if(this.city=="全部城市"){
        this.city="";
      }
      this.onChange();
    },

    onChangeProvince(data) {
      console.log(data);
      this.form.province = data.value;

      //查询身份现在有的开源社区个数
      this.genCommunityName(data.value);

    },
    async genCommunityName(province) {
      const { data } = await getCommunityProvinceCount(province);
      console.log(data);
      if(this.localStorageType==1){
        this.form.communityName = province + "开源医疗" + data;
      }
      else if(this.localStorageType==2){
        this.form.communityName = province + "开源企业" + data;
      }
    },


    onChangeCity(data) {
      console.log(data);
      this.form.city = data.value;
    },
  },

  created() {
    this.initData(1);
    this.initData2();
  },
};
</script>
<style>
.el-dialog__body {
  max-height: 60vh;
  overflow-y: auto;
}
</style>
<style scoped lang="scss">

.community {
  background-color: #f4f8ff;

  &-banner {
    position: relative;

    img {
      width: 100%;
    }

    &-content {
      position: absolute;
      top: 0px;
      left: 360px;

      h1 {
        font-size: 48px;
        color: #333;
        margin-bottom: 10px;
      }

      .desc {
        font-size: 20px;
        color: #262c33;
        margin-bottom: 20px;
      }
    }

    &-input {
      margin-bottom: 40px;
    }
  }

  &-filter {
    padding: 32px 40px;
    width: 1120px;
    background: #ffffff;
    border-radius: 6px 6px 6px 6px;
    margin: 32px auto 0;

    &-position,
    &-other {
      display: flex;

      div {
        display: flex;
        flex-wrap: wrap;
        color: #262c33;

        p {
          padding: 6px 12px;
          cursor: pointer;
        }

        .selected {
          background: #dcefff;
          border-radius: 3px 3px 3px 3px;
          color: #308eff;
        }
      }

      div + div {
        margin-left: 21px;
      }

      .value {
        margin-top: -18px;
        flex: 1;
        word-wrap: break-word;
      }

      .more {
        cursor: pointer;
      }
    }

    &-other {
      align-items: center;
      margin-top: 24px;

      div {
        display: flex;
        align-items: center;

        span {
          margin-right: 20px;
        }
      }

      .value {
        margin-top: 0;
      }
    }
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 1200px;
    margin: 0 auto;

    &-cell {
      padding: 40px;
      margin-top: 24px;
      width: 510px;
      background-color: white;
      display: flex;
      cursor: pointer;

      img {
        width: 100px;
        height: 100px;
        margin-right: 40px;
      }

      h1 {
        font-size: 24px;
        font-weight: bold;
        color: #262c33;
      }

      p {
        font-size: 18px;
        color: #262c33;

        .label {
          font-weight: bold;
        }

        span + span {
          margin-left: 40px;
        }
      }

      .icon {
        display: flex;

        span {
          display: flex;
          align-items: center;

          .el-icon {
            margin-right: 16px;
          }
        }
      }
    }
  }

  &-pagination {
    padding: 24px 0;
    display: flex;
    justify-content: center;
  }
}
</style>
